import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router ({
    routes: [
        {
            name: 'Home',
            path: '/',
            component: () => import('@/views/HomeView.vue')
        },
        {
            name: 'Sheet',
            path: '/sheet/:id',
            component: () => import('@/views/SheetView.vue')
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
});
