<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {
    },
    mounted: function() {
    }
  }
</script>

<style lang="scss">
  body {
    font-family: sans-serif;
    max-width: 1200px;
  }
</style>
